import { API_GATEWAY_API_KEY, EXIST_ACCOUNT_DATA_ENDPOINT } from "constants/base"

type existAccountData = {
  existAccountData: Boolean
}

export const existAccountData = async (email: string): Promise<Boolean> => {
  try {
    const response = await fetch(EXIST_ACCOUNT_DATA_ENDPOINT, {
      mode: "cors",
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": API_GATEWAY_API_KEY
      },
      body: JSON.stringify({ email: email }),
    })
    const res = await response.json()
    return res.result
  } catch (e) {
    throw Error("existAccountData error")
  }
}
