import * as React from 'react'
import Box from '@mui/material/Box'
import AccordionBase from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import Stack from '@mui/material/Stack'
import ArrowIcon from '@mui/icons-material/ArrowForwardIos'
import List from "applications/information/pages/Information/components/List"

export default function Accordion() {

  const [expanded, setExpanded] = React.useState(false)
  const [unreadCount, setUnreadCount] = React.useState(0)
  const [isFirst, setIsFirst] = React.useState(true)

  React.useEffect(() => {
    if (unreadCount > 0 || isFirst) {
      setExpanded(true)
    }
  }, [unreadCount])

  return (
    <Box sx={{ m: '48px 48px 0 48px' }}>
      <AccordionBase
        onChange={() => {
          setExpanded(isFirst || !expanded)
          setIsFirst(false)
        }}
        expanded={expanded}
        defaultExpanded={true}
      >
        <AccordionSummary
          expandIcon={<ArrowIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Stack direction="row" alignItems="center">
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>システム管理者からのお知らせ</Typography>
            {unreadCount > 0 ? <Typography sx={{ color: 'red', ml: '12px' }}>未読{unreadCount}件</Typography> : ''}
          </Stack>
        </AccordionSummary>
        <AccordionDetails sx={{ p: '0', borderTop: '1px solid #f2f2f2' }}>
          <List unreadCount={unreadCount} setUnreadCount={setUnreadCount} isFirst={isFirst} setIsFirst={setIsFirst}/>
        </AccordionDetails>
      </AccordionBase>
    </Box>
  )
}
