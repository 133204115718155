/*

	お知らせ画面下部バナー サービスパネル

*/
import * as React from 'react';
import Box from '@mui/material/Box';
import CircleIcon from '@mui/icons-material/Circle';
import Panel from './Panel';
import { serviceItems, ServiceItem } from '../type';

const ServiceSeparator = () => {
  return (
    <Box sx={{ width: '12px' }}>
      <CircleIcon sx={{ width: '12px', height: '12px', color: 'lightgray' }} />
    </Box>
  )
}

export default function ServicePanels(props: {
  selectedItem: ServiceItem
  setSelectedItem: (item: ServiceItem) => void
}){
  return (
    <Box
      sx={{
        overflowX: 'auto',
        textAlign: 'center',
        backgroundColor: 'white',
        border: '1px solid lightgray',
        padding: '16px',
      }}
    >
      <Box
        sx={{
          display: "inline-flex",
          direction: "row",
          alignItems: "center",
          gap: 2,
        }}
      >
        {serviceItems.map((item, index) => {
          return (
            <React.Fragment key={item.label}>
              <Panel
                service={item}
                setSelectedItem={props.setSelectedItem}
                isSelected={props.selectedItem.label === item.label}
              />
			  {index + 1 < serviceItems.length ? (<ServiceSeparator />) : null}
            </React.Fragment>
		  )
		})}
      </Box>
    </Box>
  )
}
