/*

	詳細ダイアログ

*/
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogActions from '@mui/material/DialogActions'
import ListItemIcon from '@mui/material/ListItemIcon';
import CloseIcon from '@mui/icons-material/Close';
import Parser from 'html-react-parser'
import dayjs from "dayjs"

export default function DetailDialog(props: {
  open: boolean
  information: any
  onClose: () => void
}) {

  const handleClose = (event: object, reason: string) => {
    if (reason === 'backdropClick') {
      props.onClose()
    }
  }

  return (
    <Dialog open={props.open} onClose={handleClose} fullWidth={true} maxWidth="md">
      <DialogActions sx={{ minWidth: '900px' }}>
       <Button onClick={() => props.onClose()} sx={{ minWidth: 'initial' }}>
        <ListItemIcon sx={{ minWidth: 'initial' }}>
          <CloseIcon />
        </ListItemIcon>
       </Button>
      </DialogActions>
      <DialogContent sx={{ maxHeight: '450px', p: '0 24px 20px 24px', mb: '24px' }}>
        <DialogTitle sx={{ textAlign: 'center', pt: '0' }}>{props.information.title}</DialogTitle>
        <DialogContentText sx={{ pb: '24px', textAlign: 'end' }}>{dayjs(props.information.openDate).format('YYYY/MM/DD HH:mm')}</DialogContentText>
        <DialogContentText>{Parser(String(props.information.content))}</DialogContentText>
      </DialogContent>
    </Dialog>
  )
}
