/*

	お知らせ一覧

*/
import * as React from 'react'
import DetailDialog from "applications/information/components/DetailDialog"
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import ListItemIcon from '@mui/material/ListItemIcon'
import ArrowIcon from '@mui/icons-material/ArrowForwardIos'
import { InfoData, ReadedInfoDataInput } from "gql/graphql"
import { gql, useQuery, useMutation } from "urql"
import dayjs from "dayjs"

type Props = {
  unreadCount: number
  setUnreadCount: React.Dispatch<React.SetStateAction<number>>
  isFirst: boolean
  setIsFirst: React.Dispatch<React.SetStateAction<boolean>>
}

export default function list({ unreadCount, setUnreadCount, isFirst, setIsFirst }: Props) {

  const [openDetailDialog, setOpenDetailDialog] = React.useState(false)
  const [selectedInformation, setSelectedInformation] = React.useState({})
  const [informations, setInformations] = React.useState<InfoData[]>([])
  const [totalCount, setTotalCount] = React.useState(0)
  const [page, setPage] = React.useState(1)

  const perPage = isFirst ? 2 : 10

  const [result] = useQuery({
    query: /* GraphQL */`
      query getInformations($filter: InfoDataInput!) {
        getInfoData(filter: $filter) {
          infoDataItems {
            id
            title
            content
            openDate
            readed
            delivered
          }
          totalCount
          unreadCnt
        }
      }
    `,
    variables: {
      filter: {
        pager: {
          item_offset: (page - 1) * 10,
          per_page: perPage,
        }
      }
    }
  })

  React.useEffect(() => {
    if (!result.fetching && !result.error && result.data ) {
      const newInfoData = result.data.getInfoData?.infoDataItems || []
      const uniqueInfoData = newInfoData.filter((info: InfoData) => {
        return informations.every((n: any) => n.id !== info.id)
      })
      const mergedInfoData = informations.concat(uniqueInfoData)
      setInformations(mergedInfoData)

      const unreadCount = result.data.getInfoData?.unreadCnt || 0
      setUnreadCount(unreadCount)

      const totalCount = result.data.getInfoData?.totalCount || 0
      setTotalCount(totalCount)
    }
  }, [result.fetching, result.error, result.data, setUnreadCount])

  const [saveResult , executeMutation] = useMutation(
    gql/* GraphQL */ `
      mutation saveReadedInfoData($Data: ReadedInfoDataInput!) {
        saveReadedInfoData(data: $Data) {
          id
          infoId
          userId
          readDate
        }
      }
    `
  )

  const saveReadedInfoData = async (data: ReadedInfoDataInput) => {
    const execResult = await executeMutation({
      Data: data
    })
    return execResult.data
  }

  const handleInformationClick = (information: any) => {
    if (!information.readed) {
      const updatedInformations = informations.map((n: any) =>
        n.id === information.id ? { ...n, readed: true } : n
      )
      setInformations(updatedInformations)

      const saveData: ReadedInfoDataInput = {
        infoId: information.id,
        readDate: dayjs().format('YYYY-MM-DD hh:mm:ss')
      }
      saveReadedInfoData(saveData).then(() => {
        const newUnreadCount = unreadCount - 1
        setUnreadCount(newUnreadCount)
      })
    }

    setSelectedInformation(information)
    setOpenDetailDialog(true)
  }

  const handleClose = () => {
    setOpenDetailDialog(false)
  }

  const NotReadedIcon = (information: any) => {
    if(!information.readed) {
      return (
        <ListItemText
          sx={{
            maxWidth: '50px',
            minWidth: '50px',
            color: 'red',
            border: 'solid 2px red',
            borderRadius: '50px',
            textAlign: 'center',
            m: '0 0 0 10px',
            p: '0 6px'
          }}
          primary={"未読"}
        />
      )
    }
  }

  return (
    <Box>
      <List
        sx={{
          padding: '0',
          height: isFirst ? '90px' : '160px',
          overflow: 'auto'
        }}
      >
        {informations.length === 0 ? (
          <ListItemText
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
            }}
          >
            お知らせはありません
          </ListItemText>
        ) : (
          informations.map((information: any) => (
            <ListItemButton
              key={information.id}
              sx={{
                backgroundColor: information.readed ? 'white' : 'lightgrey',
                borderBottom : '1px solid #f2f2f2'
              }}
              onClick={() => handleInformationClick(information)}
            >
              <ListItemText
                sx={{ maxWidth: '160px', minWidth: '160px' }}
                primary={dayjs(information.openDate).format('YYYY/MM/DD HH:mm')}
              />
              <Box sx={{
                maxWidth: '655px',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
                }}
              >
                {information.title}
              </Box>
              {NotReadedIcon(information)}
              <ListItemIcon sx={{ minWidth: 'initial', ml: 'auto' }}>
                <ArrowIcon />
              </ListItemIcon>
            </ListItemButton>
          ))
        )}
        {informations.length < totalCount && !isFirst && (
          <ListItemButton
            sx={{
              backgroundColor: 'white',
              borderBottom : '1px solid #f2f2f2'
            }}
            onClick={() => setPage(page + 1)}
          >
            <ListItemText
              sx={{
                textAlign: 'center',
                width: '100%'
              }}
              primary={"もっと見る"}
            />
          </ListItemButton>
        )}
      </List>
      <DetailDialog
        open={openDetailDialog}
        information={selectedInformation}
        onClose={handleClose}
      />
    </Box>
  )
}
